<template>
    <div>
        <!-- Table Container Card -->
        <h4 class="font-weight-bolder">Custom Trends List</h4>
        <b-card no-body class="mb-0">
            <b-tabs pills align="center" class="mt-3" nav-class="nav-pill-danger">
                <b-tab title="Artists" active>
                    <div class="m-2">
                        <!-- Table Top -->
                        <b-row>
                            <b-col cols="12" md="6" class="d-flex align-items-center justify-content-center mb-1 mb-md-0">
                                <!-- <label>Show</label>
                                <b-form-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="perPageOptions" :clearable="false" class="per-page-selector d-inline-block mx-50" @change="changePageTrendsList({ page: 1, itemsPerPage: $event }, 'artist')" />
                                <label>entries</label> -->
                            </b-col>
                            <b-col cols="12" md="6" class="d-flex align-items-center justify-content-center mb-1 mb-md-0">
                                <b-col cols="10">
                                    <b-form-datepicker id="datepicker-placeholder" placeholder="from date" local="en" initial-date="2022-06-16" selected-variant="warning" class="my-1" v-model="fromDate" />
                                    <b-form-datepicker id="datepicker-placeholder" placeholder="to date" local="en" initial-date="2022-06-16" selected-variant="warning" class="" v-model="toDate" />
                                </b-col>
                                <b-col cols="2">
                                    <b-button @click="setDate('artist')" variant="gradient-info" class="btn-icon">
                                        <feather-icon icon="PlusCircleIcon" />
                                    </b-button>
                                </b-col>
                            </b-col>
                        </b-row>
                    </div>

                    <b-table ref="refUserListTable" class="position-relative pb-3" :items="typeList[0].table" :per-page="customPerPage" responsive :fields="customTableColumns" primary-key="id" show-empty empty-text="No matching records found">
                        <!-- Column: Music -->
                        <template #cell(rank)="data">
                            <span class="font-weight-bolder text-primary">{{ data.item.rank }}</span>
                        </template>
                        <!-- Column: Genre -->
                        <template #cell(from-date)="data">
                            <div class="text-nowrap">
                                <span class="align-text-top text-capitalize text-warning">{{ data.item.from_date }}</span>
                            </div>
                        </template>
                        <template #cell(to-date)="data">
                            <div class="text-nowrap">
                                <span class="align-text-top text-capitalize text-warning">{{ data.item.to_date }}</span>
                            </div>
                        </template>
                        <!-- Column: Actions -->
                        <template #cell(actions)="data">
                            <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
                                <template #button-content>
                                    <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
                                </template>
                                <b-dropdown-item
                                    :to="{
                                        name: 'apps-trending-custom-detail',
                                        params: { id: data.item.id, type: 'artist' },
                                    }"
                                >
                                    <feather-icon icon="FileTextIcon" />
                                    <span class="align-middle ml-50">Details</span>
                                </b-dropdown-item>

                                <b-dropdown-item @click="deleteManualtrends(data.item.id, 'artist')">
                                    <feather-icon icon="TrashIcon" />
                                    <span class="align-middle ml-50">Delete</span>
                                </b-dropdown-item>
                            </b-dropdown>
                        </template>
                    </b-table>
                    <div class="mx-2 mb-2">
                        <b-row>
                            <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
                                <span class="text-muted">Showing {{ typeList[0].pagination.pageStart }} to {{ typeList[0].pagination.pageStop }} of {{ typeList[0].pagination.itemsLength }} entries</span>
                            </b-col>
                            <!-- Pagination -->
                            <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
                                <b-pagination v-model="currentPage" :total-rows="typeList[0].pagination.itemsLength" :per-page="customPerPage" first-number last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item" @change="changePageTrendsList({ page: $event, itemsPerPage: customPerPage }, 'artist')">
                                    <template #prev-text>
                                        <feather-icon icon="ChevronLeftIcon" size="18" />
                                    </template>
                                    <template #next-text>
                                        <feather-icon icon="ChevronRightIcon" size="18" />
                                    </template>
                                </b-pagination>
                            </b-col>
                        </b-row>
                    </div>
                </b-tab>
                <b-tab title="Musics">
                    <div class="m-2">
                        <b-row>
                            <!-- Per Page -->
                            <b-col cols="12" md="6" class="d-flex align-items-center justify-content-center mb-1 mb-md-0">
                                <!-- <label>Show</label>
                                <b-form-select v-model="customPerPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="perPageOptions" :clearable="false" class="per-page-selector d-inline-block mx-50" @change="changePageTrendsList({ page: 1, itemsPerPage: $event }, 'music')" />
                                <label>entries</label> -->
                            </b-col>
                            <b-col cols="12" md="6" class="d-flex align-items-center justify-content-center mb-1 mb-md-0">
                                <b-col cols="10">
                                    <b-form-datepicker id="datepicker-placeholder" placeholder="from date" local="en" initial-date="2022-06-16" selected-variant="warning" class="my-1" v-model="fromDate" />
                                    <b-form-datepicker id="datepicker-placeholder" placeholder="to date" local="en" initial-date="2022-06-16" selected-variant="warning" class="" v-model="toDate" />
                                </b-col>
                                <b-col cols="2">
                                    <b-button @click="setDate('music')" variant="gradient-info" class="btn-icon">
                                        <feather-icon icon="PlusCircleIcon" />
                                    </b-button>
                                </b-col>
                            </b-col>
                        </b-row>
                    </div>

                    <b-table ref="refUserListTable" class="position-relative pb-3" :items="typeList[1].table" :per-page="customPerPage" responsive :fields="customTableColumns2" primary-key="id" show-empty empty-text="No matching records found">
                        <template #cell(rank)="data">
                            <span class="font-weight-bolder text-primary">{{ data.item.rank }}</span>
                        </template>
                        <!-- Column: Genre -->
                        <template #cell(from-date)="data">
                            <div class="text-nowrap">
                                <span class="align-text-top text-capitalize text-warning">{{ data.item.from_date }}</span>
                            </div>
                        </template>
                        <template #cell(to-date)="data">
                            <div class="text-nowrap">
                                <span class="align-text-top text-capitalize text-warning">{{ data.item.to_date }}</span>
                            </div>
                        </template>
                        <!-- Column: Actions -->
                        <template #cell(actions)="data">
                            <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
                                <template #button-content>
                                    <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
                                </template>
                                <b-dropdown-item
                                    :to="{
                                        name: 'apps-trending-custom-detail',
                                        params: { id: data.item.id, type: 'artist' },
                                    }"
                                >
                                    <feather-icon icon="FileTextIcon" />
                                    <span class="align-middle ml-50">Details</span>
                                </b-dropdown-item>

                                <b-dropdown-item @click="deleteManualtrends(data.item.id, 'music')">
                                    <feather-icon icon="TrashIcon" />
                                    <span class="align-middle ml-50">Delete</span>
                                </b-dropdown-item>
                            </b-dropdown>
                        </template>
                    </b-table>
                    <div class="mx-2 mb-2">
                        <b-row>
                            <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
                                <span class="text-muted">Showing {{ typeList[1].pagination.pageStart }} to {{ typeList[1].pagination.pageStop }} of {{ typeList[1].pagination.itemsLength }} entries</span>
                            </b-col>
                            <!-- Pagination -->
                            <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
                                <b-pagination v-model="currentPage" :total-rows="typeList[1].pagination.itemsLength" :per-page="customPerPage" first-number last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item" @change="changePageTrendsList({ page: $event, itemsPerPage: customPerPage }, 'music')">
                                    <template #prev-text>
                                        <feather-icon icon="ChevronLeftIcon" size="18" />
                                    </template>
                                    <template #next-text>
                                        <feather-icon icon="ChevronRightIcon" size="18" />
                                    </template>
                                </b-pagination>
                            </b-col>
                        </b-row>
                    </div>
                </b-tab>
                <b-tab title="Videos">
                    <div class="m-2">
                        <b-row>
                            <!-- Per Page -->
                            <b-col cols="12" md="6" class="d-flex align-items-center justify-content-center mb-1 mb-md-0">
                                <!-- <label>Show</label>
                                <b-form-select v-model="customPerPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="perPageOptions" :clearable="false" class="per-page-selector d-inline-block mx-50" @change="changePageTrendsList({ page: 1, itemsPerPage: $event }, 'video')" />
                                <label>entries</label> -->
                            </b-col>
                            <b-col cols="12" md="6" class="d-flex align-items-center justify-content-center mb-1 mb-md-0">
                                <b-col cols="10">
                                    <b-form-datepicker id="datepicker-placeholder" placeholder="from date" local="en" initial-date="2022-06-16" selected-variant="warning" class="my-1" v-model="fromDate" />
                                    <b-form-datepicker id="datepicker-placeholder" placeholder="to date" local="en" initial-date="2022-06-16" selected-variant="warning" class="" v-model="toDate" />
                                </b-col>
                                <b-col cols="2">
                                    <b-button @click="setDate('video')" variant="gradient-info" class="btn-icon">
                                        <feather-icon icon="PlusCircleIcon" />
                                    </b-button>
                                </b-col>
                            </b-col>
                        </b-row>
                    </div>

                    <b-table ref="refUserListTable" class="position-relative pb-3" :items="typeList[2].table" :per-page="customPerPage" responsive :fields="customTableColumns2" primary-key="id" show-empty empty-text="No matching records found">
                        <template #cell(rank)="data">
                            <span class="font-weight-bolder text-primary">{{ data.item.rank }}</span>
                        </template>
                        <!-- Column: Genre -->
                        <template #cell(from-date)="data">
                            <div class="text-nowrap">
                                <span class="align-text-top text-capitalize text-warning">{{ data.item.from_date }}</span>
                            </div>
                        </template>
                        <template #cell(to-date)="data">
                            <div class="text-nowrap">
                                <span class="align-text-top text-capitalize text-warning">{{ data.item.to_date }}</span>
                            </div>
                        </template>
                        <!-- Column: Actions -->
                        <template #cell(actions)="data">
                            <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
                                <template #button-content>
                                    <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
                                </template>
                                <b-dropdown-item
                                    :to="{
                                        name: 'apps-trending-custom-detail',
                                        params: { id: data.item.id, type: 'artist' },
                                    }"
                                >
                                    <feather-icon icon="FileTextIcon" />
                                    <span class="align-middle ml-50">Details</span>
                                </b-dropdown-item>

                                <b-dropdown-item @click="deleteManualtrends(data.item.id, 'video')">
                                    <feather-icon icon="TrashIcon" />
                                    <span class="align-middle ml-50">Delete</span>
                                </b-dropdown-item>
                            </b-dropdown>
                        </template>
                    </b-table>
                    <div class="mx-2 mb-2">
                        <b-row>
                            <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
                                <span class="text-muted">Showing {{ typeList[2].pagination.pageStart }} to {{ typeList[2].pagination.pageStop }} of {{ typeList[2].pagination.itemsLength }} entries</span>
                            </b-col>
                            <!-- Pagination -->
                            <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
                                <b-pagination v-model="currentPage" :total-rows="typeList[2].pagination.itemsLength" :per-page="customPerPage" first-number last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item" @change="changePageTrendsList({ page: $event, itemsPerPage: customPerPage }, 'video')">
                                    <template #prev-text>
                                        <feather-icon icon="ChevronLeftIcon" size="18" />
                                    </template>
                                    <template #next-text>
                                        <feather-icon icon="ChevronRightIcon" size="18" />
                                    </template>
                                </b-pagination>
                            </b-col>
                        </b-row>
                    </div>
                </b-tab>
            </b-tabs>
        </b-card>
        <AddMusicToPlaylistList />
    </div>
</template>

<script>
import AddMusicToPlaylistList from "@/views/apps/play-list/AddMusicToPlayList.vue";
import { BTabs, BTab, BCard, BRow, BCol, BFormInput, BFormSelect, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem, BPagination, BFormDatepicker } from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted, onMounted } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import PlayListListFilters from "./PlayListListFilters.vue";
import useTrendingList from "./useTrendingList";
import userStoreModule from "../user/userStoreModule";
import PlayListRepository from "@/abstraction/repository/playListRepository";

const playlistRepository = new PlayListRepository();
export default {
    components: {
        PlayListListFilters,
        AddMusicToPlaylistList,
        BCard,
        BRow,
        BCol,
        BFormInput,
        BFormSelect,
        BButton,
        BTable,
        BMedia,
        BAvatar,
        BLink,
        BBadge,
        BDropdown,
        BDropdownItem,
        BPagination,
        BTabs,
        BTab,
        vSelect,
        BFormDatepicker,
    },
    setup() {
        const {
            perPageOptions,
            playlists,
            musics,
            videos,
            type,
            fromDate,
            toDate,
            pagination,
            musicsPagination,
            videoPagination,
            perPage,
            customPerPage,
            sortBy,
            tableColumns,
            realMusicTableColumns,
            customTableColumns,
            customTableColumns2,
            realTableColumns,
            currentPage,
            fetchManualTrends,
            deleteManualtrends,
            typeList,
            // Extra Filters
            roleFilter,
            planFilter,
            statusFilter,
        } = useTrendingList();
        const choosedTab = ref("artists");

        onMounted(() => {
            for (let i = 0; i < typeList.value.length; i++) {
                fetchManualTrends({ page: 1, itemsPerPage: customPerPage.value }, typeList.value[i].type).then((res) => {
                    typeList.value[i].table = res.data.sort(function(a,b){return a.rank - b.rank});
                    typeList.value[i].pagination = res.pagination;
                });
            }
        });
        const changePageTrendsList = (paginate, type) => {
            if (type === "artist") {
                fetchManualTrends(paginate, "artist").then((res) => {
                    typeList.value[0].table = res.data;
                    typeList.value[0].pagination = res.pagination;
                });
            } else if (type === "music") {
                fetchManualTrends(paginate, "music").then((res) => {
                    typeList.value[1].table = res.data;
                    typeList.value[1].pagination = res.pagination;
                });
            } else if (type === "video") {
                fetchManualTrends(paginate, "video").then((res) => {
                    typeList.value[2].table = res.data;
                    typeList.value[2].pagination = res.pagination;
                });
            }
        };

        const setDate = (typeName) => {
            fetchManualTrends("", typeName).then((res) => {
                console.log(res);
                if (typeName == "artist") {
                    typeList.value[0].table = res.data;
                    typeList.value[0].pagination = res.pagination;
                } else if (typeName == "music") {
                    typeList.value[1].table = res.value;
                    typeList.value[1].pagination = res.pagination;
                } else if (typeName == "video") {
                    typeList.value[2].table = res.value;
                    typeList.value[2].pagination = res.pagination;
                }
            });
        };
        return {
            playlists,
            musics,
            videos,
            type,
            pagination,
            musicsPagination,
            videoPagination,
            // Filter
            avatarText,
            perPageOptions,
            changePageTrendsList,
            fetchManualTrends,
            perPage,
            customPerPage,
            currentPage,
            sortBy,
            tableColumns,
            deleteManualtrends,
            // Extra Filters
            roleFilter,
            planFilter,
            statusFilter,
            choosedTab,
            typeList,
            setDate,
            toDate,
            fromDate,
            realMusicTableColumns,
            customTableColumns2,
            realTableColumns,
            customTableColumns,
        };
    },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
    width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

a {
    color: $red;
}
</style>
